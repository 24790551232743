<template>
  <div>
    <app-card
      :heading="$t('message.exactOnline')"
      col-classes="xl12 lg12 md12 sm12 xs12"
      custom-classes="mb-0"
    >
      <span v-if="data.lastLoginAt">
        Last Login: {{ lastLoginAtFormatted }}
      </span>
      <v-btn
        v-if="data.loginUrl"
        dark
        @click="loginExactOnline"
      >
        {{ loginButtonText }}
      </v-btn>
    </app-card>
  </div>
</template>

<script>

import Request from '../../../helpers/request'
import moment from 'moment'

export default {
  components: { },
  mixins: [Request],
  data () {
    return {
      data: {}
    }
  },
  computed: {
    loginButtonText () {
      return this.data.lastLoginAt ? 'Re-Login' : 'Login'
    },
    lastLoginAtFormatted () {
      return moment(this.data.lastLoginAt).format('MM/DD/YYYY')
    }
  },
  watch: {

  },
  async beforeMount () {
    this.fetchExactProperties()
  },
  methods: {
    async fetchExactProperties () {
      try {
        const { data } = await this.request('GET', '/exact-online/properties')

        this.data = data
      } catch (error) {

      }
    },
    loginExactOnline () {
      window.location.href = this.data.loginUrl
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
